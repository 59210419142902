<template>
  <div class="goods-info" v-if="info">
    <div class="top">
      <div class="top-first">
        <div class="time">{{ time }}</div>
        <div class="lump"></div>
        <div class="cell">
          <div class="cell-num">
            {{ cell }}
          </div>
          <div class="cell-icon">
            <div class="inner"></div>
          </div>
        </div>
      </div>
      <div class="top-second">商品详情</div>
    </div>

    <div class="body">
      <div class="header">
        <el-carousel trigger="click" >
          <el-carousel-item
            v-for="(item, index) in bannerList"
            :key="item.type + index"
          >
            <video
              v-if="item.type === 'video'"
              class="video-wrap"
              :src="item.value"
              enable-danmu
              danmu-btn
              controls
              @click="onHandlePreview(item, index)"
            ></video>
            <el-image
              class="image-wrap"
              v-else
              :src="item.value"
              mode="aspectFill"
              @click="onHandlePreview(item, index)"
            ></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="price-wrap">
        <div class="price">
          <span class="left">￥</span>
          <span class="middle">{{ info.price.split(".")[0] }}</span>
          <span class="last">.{{ info.price.split(".")[1] }}</span>
          <span class="right">{{ `/${info.unit_type || "件"}` }}</span>
          <span
            class="Drawaline"
            v-if="
              Number(info.before_sale_cycle_price) > 0 &&
              Number(info.before_sale_cycle_price) > Number(info.price)
            "
            >￥{{ info.before_sale_cycle_price }}</span
          >
        </div>
        <div class="right-wrap">
          <div class="count-wrap">
            <div class="first">
              已售
              <span class="count-num">{{ info.sales || 0 }}</span
              >件
            </div>
            <div class="new" v-if="info.sales_item_falg === 1">
              <span class="middle">|</span>
              <span class="count-num">本周新品</span>
            </div>
            <div class="count" v-if="info.sales_item_falg === 2">
              <span class="middle">|</span>
              近七日售
              <span class="count-num">{{ info.sales_item }}</span
              >件
            </div>
            <!-- 非果蔬 显示30天售出 start -->
            <div class="count" v-if="info.sales_item_falg === 3">
              <span class="middle">|</span>
              近三十日售
              <span class="count-num">{{ info.sales_item }}</span
              >件
            </div>
            <!-- 非果蔬 显示30天售出 end -->
          </div>

          <!-- 1果蔬2非果蔬 -->
          <div class="new-tag-number" v-if="info.categories_type == 1">
            7天售后率：{{
              Number(info.aftersale_percent_7) != 0
                ? `${info.aftersale_percent_7}%`
                : "无"
            }}
          </div>
          <div class="new-tag-number" v-else>
            30天售后率：{{
              Number(info.aftersale_percent_30) != 0
                ? `${info.aftersale_percent_30}%`
                : "无"
            }}
          </div>
        </div>
      </div>
      <div class="bodys">
        <div class="title-wrap">
          <div class="title-tip">
            <!-- 品类标签 end -->
            <span class="title">{{ `${info.title}` }}</span>
          </div>
        </div>
        <div class="tag-new">
          <div class="tag-wrap-new">
            {{ (info.stock && `库存${info.stock}`) || "今日售罄" }}
          </div>
          <div class="tag-wrap-new" v-if="info.min_num > 1">
            {{ `起订${info.min_num}` }}
          </div>
          <div class="tag-wrap-new" v-if="info.multiple > 1">
            {{ `倍数${info.multiple}` }}
          </div>
          <div class="tag-wrap-new" v-if="info.restrict">
            {{ `限购${info.restrict}` }}
          </div>
        </div>

        <div class="subtitle-wrap">
          <div class="child">
            <div class="child-wrap">
              <div class="child-label">毛重</div>
              <div class="child-value">
                约<span class="bold">{{ info.gross_weight }}</span
                >斤
              </div>
            </div>
          </div>
          <div class="child">
            <div class="child-wrap">
              <div class="child-label">毛单价</div>
              <div class="child-value">
                约<span class="bold">￥{{ info.gross_weight_price }}</span
                >/斤
              </div>
            </div>
          </div>
          <div class="child">
            <div class="child-wrap">
              <div class="child-label">净重</div>
              <div class="child-value">
                约<span class="bold">{{ info.weight }}</span
                >斤
              </div>
            </div>
          </div>
          <div class="child">
            <div class="child-wrap">
              <div class="child-label">净单价</div>
              <div class="child-value">
                约<span class="bold">￥{{ info.weight_price }}</span
                >/斤
              </div>
            </div>
          </div>
        </div>

        <div class="card-wrap">
          <div class="card-title">商品详情</div>
          <div class="card-content padding20">
            <div v-for="(child, index) of detailsList" :key="index">
              <div class="card-item" v-if="child.children">
                <div
                  class="card-left"
                  v-for="(item, i) in child.children"
                  :key="i"
                >
                  <div class="card-label">{{ item.label }}:</div>
                  <div class="card-cnt">{{ item.value }}</div>
                </div>
              </div>
              <div class="card-item" v-else>
                <div class="card-left">
                  <div class="card-label">{{ child.label }}:</div>
                  <div class="card-cnt">{{ child.value }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 文件预览 start -->
    <c-preview-file
      v-model="dialogVisible"
      :fileList="fileList"
      :fileIndex="previewIndex"
      :fileType="fileType"
    ></c-preview-file>
    <!-- 文件预览 end -->
  </div>
</template>

<script>
import CPreviewFile from "@/components/common/CPreviewFile/index.vue";
import { formatDate } from "@/utils//tools/date.js";
import { IS_AFTER_SALE } from "@/views/general/utils/enum/index";

export default {
  name: "GoodsDetails",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    details: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    CPreviewFile,
  },
  data() {
    return {
      time: formatDate(new Date(), "hh:mm"), // 时间
      cell: "100%", // 电池电量
      rules: "虚假、恶意售后、陈货换新将永久关停账户，并赔偿平台相关损失。",
      rulesSub: "出现未在售后赔付范围内争议，以平台最终判定为准。",
      dialogVisible: false, // 图片预览是否开启
      previewIndex: 0, // 下标
      fileType: null, // 当前文件后缀
      info:'',
      bannerList:[],
      detailsList:[]
    };
  },
  computed: {
    /**
     * 获取文件类型
     */
    getFileType() {
      return (file) => {
        const extIndex = file.value.lastIndexOf(".");
        const fileType = file.value.slice(extIndex + 1).toLocaleLowerCase();
        return fileType;
      };
    },
  
    /**
     * 预览的文件列
     */
    fileList() {
      let list = [];
      list = this.bannerList.map((item) => {
        return {
          name: item.label,
          url: item.value,
        };
      });
      return list;
    },
 

  },
  watch:{
    details: {
      handler(newVal, oldVal) {
        this.$api.login
      .productdetail({ snapshot_id: newVal.snapshot_id,sku_code:newVal.sku_code,type:newVal.typenew?newVal.typenew:1 })
      .then((res) => {
        // console.log(res, 11111111111111111111111);
        this.info = res.data;
        const { aftersale_cond, as_cond } = res.data;
          let asCond = '';
          if (aftersale_cond?.length) {
            aftersale_cond.map((item) => {
              asCond += `${item.name} `;
            });
          }
          // console.log('🆒 asCond', asCond);
          this.info.nickname = res.data.buyer.nickname;
          this.info.as_cond = asCond || as_cond;
        this.bannerListall();
        this.detailsListall();
      });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
   
  },
  methods: {
      /**
     * 滚动数据列(视频 && 图片)
     */
     bannerListall() {
      const list = [];
      const { srp_video, kv, srp_pic } = this.info;
      srp_video &&
        list.push({
          label: "视频",
          value: srp_video,
          type: "video",
        });
      const kvs =
        (kv && [
          {
            label: "图片",
            value: kv,
            type: "pic",
          },
        ]) ||
        [];
      const picList =
        (srp_pic &&
          srp_pic.split(",").map((item) => {
            return {
              label: "图片",
              value: item,
              type: "pic",
            };
          })) ||
        [];
      this.bannerList = list.concat(kvs).concat(picList);
  
    },
        /**
     * 详情列表
     */
     detailsListall() {
      const list = [];
      const {
        origin_name,
        grade,
        spec,
        min_se,
        max_se,
        ded,
        as_cond: as_cond_last,
        intro,
        delivery_time,
        arrival_time,
        date_of_manufacture,
        shelf_life_time,
        shelf_life_type,
        nickname,
      } = this.info;
      let detailsList = [];

      if (this.info.categories_type == 1) {
        console.log("是不是是果蔬");
        detailsList = [
          {
            label: "业务编号",
            value: nickname,
          },
          {
            label: "简介",
            value: intro,
          },

          {
            children: [
              {
                label: "产地",
                value: origin_name,
              },
              {
                label: "等级",
                value: grade,
              },
            ],
          },

          {
            children: [
              {
                label: "产地发货时间",
                value: delivery_time,
              },
              {
                label: "货物到市场时间",
                value: arrival_time,
              },
            ],
          },
          {
            children: [
              {
                label: "最小甜度",
                value: min_se && `${min_se}%`,
              },
              {
                label: "最大甜度",
                value: max_se && `${max_se}%`,
              },
            ],
          },
          {
            label: "规格",
            value: spec,
          },

          {
            label: "免赔情况",
            value: ded,
          },
          {
            label: "可申请售后条件",
            value: as_cond_last,
          },
        ];
      } else {
        detailsList = [
          {
            label: "业务编号",
            value: nickname,
          },
          {
            label: "简介",
            value: intro,
          },
          // {
          //     children: [
          //     {
          //         label: '30天售后率',
          //         value: Number(aftersale_percent_30)!=0 ? `${aftersale_percent_30}%`:'无',
          //       },
          //       {
          //         label: '是否有售后',
          //         value: after_sale_label,
          //       },

          //     ],
          //   },
          {
            children: [
              {
                label: "产地",
                value: origin_name,
              },
              {
                label: "等级",
                value: grade,
              },
            ],
          },
          // {
          //   label: '是否有售后',
          //   value: after_sale_label,
          // },
          {
            children: [
              {
                label: "生产日期",
                value: shelf_life_type != 3 ? date_of_manufacture : "",
              },
              {
                label: "保质期",
                value: shelf_life_type != 3 ? shelf_life_time : "",
              },
            ],
          },
          {
            label: "规格",
            value: spec,
          },

          {
            label: "免赔情况",
            value: ded,
          },
          {
            label: "可申请售后条件",
            value: as_cond_last,
          },
        ];
      }

      // 过滤掉空值
      this.detailsList = [];

      detailsList.forEach((item) => {
        if (item.value) {
          this.detailsList.push(item);
        }
        if (item.children) {
          item.children = item.children.filter((child) => child.value);
          if (item.children.length > 0) {
            this.detailsList.push(item);
          }
        }
      });
    },
    /**
     * 预览文件
     */
    onHandlePreview(file, index) {
      console.log("onHandlePreview 5", file, index);
      this.previewIndex = index;
      this.dialogVisible = true;
      this.fileType = this.getFileType(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-info {
  width: 100%;
  height: 100%;
  background-color: #f5f6f7;
  overflow: hidden;
  height: calc(100% - 50px);
  // margin: 0 auto;
  margin-bottom: 20px;
  border: 3.5px solid rgb(63, 63, 63);
  border-radius: 25px;
  box-shadow: 5px 8px 8px 0px rgba(0, 0, 0, 0.3);
  /deep/ .el-carousel__container{
    height: 375px !important;
  }
  /** 模拟手机顶部样式 */
  .top {
    height: 65px;
    background-color: rgba(0, 0, 0, 0.05);
    &-first {
      height: 40px;
      display: flex;
      justify-content: center;
      .time {
        width: 22%;
        line-height: 45px;
        transform: scale(0.6);
        font-size: 12px;
      }
      .lump {
        flex: 1;
        height: 30px;
        border-bottom-right-radius: 18px;
        border-bottom-left-radius: 18px;
        background-color: #000;
      }
      .cell {
        position: relative;
        width: 22%;
        display: flex;
        line-height: 45px;
        &-num {
          position: absolute;
          left: -5px;
          width: 45px;
          transform: scale(0.6);
          font-size: 12px;
        }
        &-icon {
          position: absolute;
          bottom: 14px;
          left: 26px;
          width: 15px;
          height: 7px;
          padding: 0.5px;
          border: 0.5px solid #000;
          box-sizing: border-box;
          border-radius: 1.5px;
          .inner {
            position: relative;
            top: 0.5px;
            left: 0.8px;
            width: 10px;
            height: 3px;
            background-color: #000;
          }
          &:after {
            content: "";
            position: absolute;
            top: 1.5px;
            right: -3px;
            width: 2.5px;
            height: 2.5px;
            background-color: #000;
          }
        }
      }
    }
    &-second {
      font-size: 12px;
      font-weight: 550;
      text-align: center;
    }
  }
  .body {
    height: 90%;
    overflow-y: auto;
  }
  .header {
    height: 375px;
    background-color: #000;
    overflow: hidden;
  }
  .bodys {
    padding: 10px;
    padding-top: 5px;
    background-color: #fff;
  }
  .price-wrap {
    display: flex;
    padding: 4px 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    color: #fff;
    background: linear-gradient(180deg, #51c365 0%, #13ae67 50%);
    margin-top: -20px;
    z-index: 2;
    position: relative;
    .price {
      flex: 1;
      font-size: 19px;

      .left {
        position: relative;
        bottom: 2px;
      }

      .middle {
        padding-left: 4px;
        font-size: 35px;
        font-weight: 600;
      }

      .last {
        font-size: 24px;
        font-weight: 600;
      }

      .right {
        position: relative;
        bottom: 2px;
        padding-left: 4px;
      }
    }

    .right-wrap {
      text-align: right;

      .tag {
        padding-left: 1px;
        justify-content: flex-start;
      }

      // .text {
      //   font-size: 26rpx;
      //   margin-bottom: 15rpx;
      // }
      .count-wrap {
        display: flex;
        margin-bottom: 8px;
        font-size: 13px;
        color: #fff;

        .middle {
          padding: 0 8rpx;
        }

        .count-num {
          color: #fee68b;
        }
      }
    }
  }
  .title-wrap {
    padding: 0 10px;
    background: #ffffff;

    .title-tip {
      margin-bottom: 10px;
      font-size: 19px;
      // font-weight: 600;
      // text-shadow: 0 0 0 #000;
      font-weight: bold;

      .title {
        word-break: break-all;
      }
    }
  }
  .tag-new {
    display: flex;
    gap: 5px;
    .tag-wrap-new {
      background: #f7f8f9;
      padding: 4px 5px;
      border-radius: 4px;
      border: 1px solid #eeeeee;
      box-sizing: border-box;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #c88c37;
    }
  }
  .subtitle-wrap {
    display: flex;
    padding-bottom: 10px;
    margin-top: 8px;

    .child {
      padding: 8px 4px;
      font-size: 13px;
      color: #666666;
      background-color: #f7f8f9;

      &-label {
        padding-bottom: 10px;
      }

      &-value {
        .bold {
          font-weight: bold;
          color: #333333;
        }
      }

      &:nth-of-type(1),
      &:nth-of-type(3) {
        flex: 1;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;

        .child-wrap {
          padding-right: 3px;
          border-right: 1px solid #dddddd;
        }
      }

      &:nth-of-type(2) {
        margin-right: 7px;
      }

      &:nth-of-type(2),
      &:nth-of-type(4) {
        flex: 1;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
  /** 卡片样式 */
  .card-wrap {
    &.padding30 {
      padding-bottom: 15px;
    }

    .card-title {
      display: inline-block;
      position: relative;
      padding: 10px 0;
      margin-left: 12px;
      font-size: 13px;
      font-weight: 600;
      color: #333333;

      &:after {
        content: "";
        position: absolute;
        bottom: 5px;
        left: 0;
        width: 50px;
        height: 12px;
        background: linear-gradient(180deg, #53d199 0%, #ffffff 100%);
        opacity: 0.4;
      }
    }

    .card-content {
      padding-top: 10px;
      background-color: #fff;

      .charts-y-title {
        padding-left: 9px;
        font-size: 12px;
        color: #b3b3b3;
      }

      &.padding20 {
        padding: 10px;
      }

      .card-item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f7f8f9;
        padding: 12px 0;

        box-sizing: border-box;

        .card-left {
          flex: 1;
          display: flex;
          // align-items: center;
        }

        .card-right {
          flex: 1;
          display: flex;
          // align-items: center;
        }

        .card-label {
          font-weight: 400;
          font-size: 13px;
          color: #666666;
          margin-right: 12rpx;
        }

        .card-cnt {
          font-weight: 400;
          font-size: 13px;
          color: #000000;
          flex-wrap: wrap;
          flex: 1;
        }
      }
    }

    .card-row {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      line-height: 30px;
      color: #666666;

      &__label {
        width: 100px;
        font-size: 14px;
        font-weight: 600;
      }

      &__value {
        flex: 1;
        font-size: 13px;
        color: #000;
      }

      &.hidden {
        display: none;
      }
    }

    .card-more {
      margin-top: 10px;
      text-align: center;
      font-size: 13px;
      color: #666666;
    }
  }
  /deep/ .el-carousel {
    .el-carousel__arrow--left {
      background-color: #0a5edb !important;
      top: 40% !important;
    }
    .el-carousel__arrow--right {
      background-color: #0a5edb !important;
      top: 40% !important;
    }
    .video-wrap {
      width: 100%;
      height: 375px;
      cursor: pointer;
    }
    .el-image {
      width: 100%;
      height: 375px;
      cursor: pointer;
    }
  }
}
</style>
