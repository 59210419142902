var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.detailsVisible
    ? _c(
        "div",
        {
          ref: "dian",
          class: _vm.goodsDetail.typenew == 2 ? "dialogbj yangsdk" : "dialogbj",
        },
        [
          _c("div", { staticStyle: { "text-align": "right" } }, [
            _c("i", {
              staticClass: "el-icon-circle-close",
              on: {
                click: function ($event) {
                  _vm.detailsVisible = false
                },
              },
            }),
          ]),
          _c("goods-details", { attrs: { details: _vm.goodsDetail } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }