<template>
     <div v-if="detailsVisible" :class="goodsDetail.typenew==2?'dialogbj yangsdk':'dialogbj'" ref="dian">
      <div style="text-align: right">
        <i class="el-icon-circle-close" @click="detailsVisible = false"></i>
      </div>

      <goods-details
        :details="goodsDetail"
      ></goods-details>
    </div>
  
</template>
<script>
import GoodsDetails from "../views/general/modules/goods-change-dialog/modules/goods-details.vue";

export default {
  name: "GlobalDetails",
  components: { GoodsDetails },
  data() {
    return {
      detailsVisible: false,
      goodsDetail: "",
    };
  },
  watch: {},
  mounted() {},
  methods: {
    
    hqlist(e) {
        console.log(this.$refs)
      this.detailsVisible = true;
      this.goodsDetail = e;
    },
  },
  created() {},
};
</script>


<style lang="scss" scoped>
// .GlobalDetails{
//     width: 100%;
//     height: 100%;
// }
.dialogbj {
  z-index: 999 !important;
  // text-align: right;
  i {
    font-size: 30px;
  }
  width: 375px;
  height: 820px;
  position: fixed;
  background-color: #fff;
  top: 50%;
  left: 70%;
  // background-color: #000;

  transform: translateX(-50%) translateY(-50%);
}
.yangsdk{
  left: 88%;
}
</style>