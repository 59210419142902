var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.info
    ? _c(
        "div",
        { staticClass: "goods-info" },
        [
          _c("div", { staticClass: "top" }, [
            _c("div", { staticClass: "top-first" }, [
              _c("div", { staticClass: "time" }, [_vm._v(_vm._s(_vm.time))]),
              _c("div", { staticClass: "lump" }),
              _c("div", { staticClass: "cell" }, [
                _c("div", { staticClass: "cell-num" }, [
                  _vm._v(" " + _vm._s(_vm.cell) + " "),
                ]),
                _vm._m(0),
              ]),
            ]),
            _c("div", { staticClass: "top-second" }, [_vm._v("商品详情")]),
          ]),
          _c("div", { staticClass: "body" }, [
            _c(
              "div",
              { staticClass: "header" },
              [
                _c(
                  "el-carousel",
                  { attrs: { trigger: "click" } },
                  _vm._l(_vm.bannerList, function (item, index) {
                    return _c(
                      "el-carousel-item",
                      { key: item.type + index },
                      [
                        item.type === "video"
                          ? _c("video", {
                              staticClass: "video-wrap",
                              attrs: {
                                src: item.value,
                                "enable-danmu": "",
                                "danmu-btn": "",
                                controls: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onHandlePreview(item, index)
                                },
                              },
                            })
                          : _c("el-image", {
                              staticClass: "image-wrap",
                              attrs: { src: item.value, mode: "aspectFill" },
                              on: {
                                click: function ($event) {
                                  return _vm.onHandlePreview(item, index)
                                },
                              },
                            }),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "price-wrap" }, [
              _c("div", { staticClass: "price" }, [
                _c("span", { staticClass: "left" }, [_vm._v("￥")]),
                _c("span", { staticClass: "middle" }, [
                  _vm._v(_vm._s(_vm.info.price.split(".")[0])),
                ]),
                _c("span", { staticClass: "last" }, [
                  _vm._v("." + _vm._s(_vm.info.price.split(".")[1])),
                ]),
                _c("span", { staticClass: "right" }, [
                  _vm._v(_vm._s(`/${_vm.info.unit_type || "件"}`)),
                ]),
                Number(_vm.info.before_sale_cycle_price) > 0 &&
                Number(_vm.info.before_sale_cycle_price) >
                  Number(_vm.info.price)
                  ? _c("span", { staticClass: "Drawaline" }, [
                      _vm._v("￥" + _vm._s(_vm.info.before_sale_cycle_price)),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "right-wrap" }, [
                _c("div", { staticClass: "count-wrap" }, [
                  _c("div", { staticClass: "first" }, [
                    _vm._v(" 已售 "),
                    _c("span", { staticClass: "count-num" }, [
                      _vm._v(_vm._s(_vm.info.sales || 0)),
                    ]),
                    _vm._v("件 "),
                  ]),
                  _vm.info.sales_item_falg === 1
                    ? _c("div", { staticClass: "new" }, [
                        _c("span", { staticClass: "middle" }, [_vm._v("|")]),
                        _c("span", { staticClass: "count-num" }, [
                          _vm._v("本周新品"),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.info.sales_item_falg === 2
                    ? _c("div", { staticClass: "count" }, [
                        _c("span", { staticClass: "middle" }, [_vm._v("|")]),
                        _vm._v(" 近七日售 "),
                        _c("span", { staticClass: "count-num" }, [
                          _vm._v(_vm._s(_vm.info.sales_item)),
                        ]),
                        _vm._v("件 "),
                      ])
                    : _vm._e(),
                  _vm.info.sales_item_falg === 3
                    ? _c("div", { staticClass: "count" }, [
                        _c("span", { staticClass: "middle" }, [_vm._v("|")]),
                        _vm._v(" 近三十日售 "),
                        _c("span", { staticClass: "count-num" }, [
                          _vm._v(_vm._s(_vm.info.sales_item)),
                        ]),
                        _vm._v("件 "),
                      ])
                    : _vm._e(),
                ]),
                _vm.info.categories_type == 1
                  ? _c("div", { staticClass: "new-tag-number" }, [
                      _vm._v(
                        " 7天售后率：" +
                          _vm._s(
                            Number(_vm.info.aftersale_percent_7) != 0
                              ? `${_vm.info.aftersale_percent_7}%`
                              : "无"
                          ) +
                          " "
                      ),
                    ])
                  : _c("div", { staticClass: "new-tag-number" }, [
                      _vm._v(
                        " 30天售后率：" +
                          _vm._s(
                            Number(_vm.info.aftersale_percent_30) != 0
                              ? `${_vm.info.aftersale_percent_30}%`
                              : "无"
                          ) +
                          " "
                      ),
                    ]),
              ]),
            ]),
            _c("div", { staticClass: "bodys" }, [
              _c("div", { staticClass: "title-wrap" }, [
                _c("div", { staticClass: "title-tip" }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s(`${_vm.info.title}`)),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "tag-new" }, [
                _c("div", { staticClass: "tag-wrap-new" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        (_vm.info.stock && `库存${_vm.info.stock}`) ||
                          "今日售罄"
                      ) +
                      " "
                  ),
                ]),
                _vm.info.min_num > 1
                  ? _c("div", { staticClass: "tag-wrap-new" }, [
                      _vm._v(" " + _vm._s(`起订${_vm.info.min_num}`) + " "),
                    ])
                  : _vm._e(),
                _vm.info.multiple > 1
                  ? _c("div", { staticClass: "tag-wrap-new" }, [
                      _vm._v(" " + _vm._s(`倍数${_vm.info.multiple}`) + " "),
                    ])
                  : _vm._e(),
                _vm.info.restrict
                  ? _c("div", { staticClass: "tag-wrap-new" }, [
                      _vm._v(" " + _vm._s(`限购${_vm.info.restrict}`) + " "),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "subtitle-wrap" }, [
                _c("div", { staticClass: "child" }, [
                  _c("div", { staticClass: "child-wrap" }, [
                    _c("div", { staticClass: "child-label" }, [_vm._v("毛重")]),
                    _c("div", { staticClass: "child-value" }, [
                      _vm._v(" 约"),
                      _c("span", { staticClass: "bold" }, [
                        _vm._v(_vm._s(_vm.info.gross_weight)),
                      ]),
                      _vm._v("斤 "),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "child" }, [
                  _c("div", { staticClass: "child-wrap" }, [
                    _c("div", { staticClass: "child-label" }, [
                      _vm._v("毛单价"),
                    ]),
                    _c("div", { staticClass: "child-value" }, [
                      _vm._v(" 约"),
                      _c("span", { staticClass: "bold" }, [
                        _vm._v("￥" + _vm._s(_vm.info.gross_weight_price)),
                      ]),
                      _vm._v("/斤 "),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "child" }, [
                  _c("div", { staticClass: "child-wrap" }, [
                    _c("div", { staticClass: "child-label" }, [_vm._v("净重")]),
                    _c("div", { staticClass: "child-value" }, [
                      _vm._v(" 约"),
                      _c("span", { staticClass: "bold" }, [
                        _vm._v(_vm._s(_vm.info.weight)),
                      ]),
                      _vm._v("斤 "),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "child" }, [
                  _c("div", { staticClass: "child-wrap" }, [
                    _c("div", { staticClass: "child-label" }, [
                      _vm._v("净单价"),
                    ]),
                    _c("div", { staticClass: "child-value" }, [
                      _vm._v(" 约"),
                      _c("span", { staticClass: "bold" }, [
                        _vm._v("￥" + _vm._s(_vm.info.weight_price)),
                      ]),
                      _vm._v("/斤 "),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "card-wrap" }, [
                _c("div", { staticClass: "card-title" }, [_vm._v("商品详情")]),
                _c(
                  "div",
                  { staticClass: "card-content padding20" },
                  _vm._l(_vm.detailsList, function (child, index) {
                    return _c("div", { key: index }, [
                      child.children
                        ? _c(
                            "div",
                            { staticClass: "card-item" },
                            _vm._l(child.children, function (item, i) {
                              return _c(
                                "div",
                                { key: i, staticClass: "card-left" },
                                [
                                  _c("div", { staticClass: "card-label" }, [
                                    _vm._v(_vm._s(item.label) + ":"),
                                  ]),
                                  _c("div", { staticClass: "card-cnt" }, [
                                    _vm._v(_vm._s(item.value)),
                                  ]),
                                ]
                              )
                            }),
                            0
                          )
                        : _c("div", { staticClass: "card-item" }, [
                            _c("div", { staticClass: "card-left" }, [
                              _c("div", { staticClass: "card-label" }, [
                                _vm._v(_vm._s(child.label) + ":"),
                              ]),
                              _c("div", { staticClass: "card-cnt" }, [
                                _vm._v(_vm._s(child.value)),
                              ]),
                            ]),
                          ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ]),
          _c("c-preview-file", {
            attrs: {
              fileList: _vm.fileList,
              fileIndex: _vm.previewIndex,
              fileType: _vm.fileType,
            },
            model: {
              value: _vm.dialogVisible,
              callback: function ($$v) {
                _vm.dialogVisible = $$v
              },
              expression: "dialogVisible",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cell-icon" }, [
      _c("div", { staticClass: "inner" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }